/**
 * Transform date to string in YYYY-MM-DD format.
 * Returns empty string if date was null or undefined.
 * @param date Date to transform.
 */
export function getDateString(date?: Date | null): string {
	if (!date) {
		return '';
	}

	const newDate = getDateWithoutTimezoneOffset(date);

	// Get only date information from ISO formatted string.
	return newDate.toISOString().substring(0, 10);
}

/**
 * Get date without timezone offset.
 * @param date Date.
 */
export function getDateWithoutTimezoneOffset(date: Date): Date {
	// Get timezone offset in milliseconds.
	const userTimezoneOffset = date.getTimezoneOffset() * 60 * 1000;
	return new Date(date.getTime() - userTimezoneOffset);
}

type IsDate<
	TInput extends (string | null | undefined),
> = TInput extends string
	? Date
	: null;

/**
 * Get date without timezone offset.
 * If date string is empty or invalid returns 'null'.
 * @param dateString Date.
 */
export function parseDateFromServer<T extends(string | null | undefined)>(dateString: T): IsDate<T> {
	if (!dateString) {
		return null as IsDate<T>;
	}

	const date = new Date(dateString);

	if (isInvalidDate(date)) {
		return null as IsDate<T>;
	}

	// Get timezone offset in milliseconds.
	const userTimezoneOffset = date.getTimezoneOffset() * 60 * 1000;
	return new Date(date.getTime() + userTimezoneOffset) as IsDate<T>;
}

/**
 * Generate years range.
 * @example
 * before = 20, after = 20, currentYear = 2021
 * Result will be [2001, 2002, ..., 2021, ..., 2040, 2041].
 * @param before Years before current.
 * @param after Years after current.
 */
export function generateYearsRange(before = 30, after = before): number[] {
	const size = before + after + 1;
	const start = new Date().getUTCFullYear() - before;
	return [...Array(size).keys()].map(i => i + start);
}

/**
 * Returns Date object from Date object and time string.
 * @example
 * date = 'Thu Sep 30 2021 00:00:00 GMT+0500', time = '10:00 AM'.
 * Result will be 'Thu Sep 30 2021 10:00:00 GMT+0500'
 * @param date Date object without specified time.
 * @param time Time that should be added to Date.
 */
export function convertDateAndTimeIntoDate(date: Date | null, time: string): Date | null {
	if (!date) {
		return null;
	}

	if (!time) {
		return new Date(date);
	}

	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	const [hoursAndMinutes, timeOfDay] = time.split(' ');
	let hours = Number(hoursAndMinutes.split(':')[0]);
	if (timeOfDay) {
		hours = timeOfDay.toLowerCase() === 'am' ?
			Number(hoursAndMinutes.split(':')[0]) :
			(Number(hoursAndMinutes.split(':')[0]) + 12);
	}
	const minutes = Number(hoursAndMinutes.split(':')[1]);
	return new Date(year, month, day, hours, minutes);
}

/**
 * Returns time in readable format from Date object.
 * @example
 * date = 'Thu Sep 30 2021 13:00:00 GMT+0500'.
 * Result will be '01:00 AM'
 * @param date Date object.
 */
export function getTimeFromDate(date: Date | null): string {
	const defaultValue = '08:00';

	if (!date) {
		return defaultValue;
	}

	return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false });
}

/**
 * Set date only for date.
 * @param oldDate Date.
 * @param updatedDate Time.
 * @returns New Date.
 */
export function setDateWithoutTimeForDate(oldDate: Date, updatedDate: Date): Date {
	const newDate = new Date(updatedDate);
	newDate.setHours(oldDate.getHours());
	newDate.setMinutes(oldDate.getMinutes());
	newDate.setSeconds(oldDate.getSeconds());
	return newDate;
}

/**
 * Add days to date.
 * @param start Start day.
 * @param days Days.
 */
export function addDaysToDate(start: Date, days: number): Date {
	const end = new Date(start);
	end.setDate(start.getDate() + days);
	return end;
}

/**
 * Add months to date.
 * @param start Start day.
 * @param months Months.
 */
export function addMonthsToDate(start: Date, months: number): Date {
	const end = new Date(start);
	end.setMonth(start.getMonth() + months);
	return end;
}

/**
 * Add days to date.
 * @param start Start day.
 * @param days Days.
 */
export function subtractDaysFromDate(start: Date, days: number): Date {
	const end = new Date(start);
	end.setDate(start.getDate() - days);
	return end;
}

/**
 * Get days difference between dates.
 * @param start Start date.
 * @param end End date.
 */
export function dateDiffInDays(start: Date, end: Date): number {
	// Discard the time and time-zone information.
	const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
	const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());

	const msPerDay = 1000 * 60 * 60 * 24;
	return Math.floor((utc2 - utc1) / msPerDay);
}

/**
 * Is date inside range. This check is exclusive.
 * @param start Start date.
 * @param end End date.
 * @param date Date to check.
 */
export function isDateInsideRange(start: Date, end: Date, date: Date): boolean {
	return date > start && date < end;
}

/**
 * Is date inside range. This check is exclusive.
 * @param date Date.
 * @param months Months to add before/after the date.
 * @returns Tuple of [startDate, endDate].
 */
export function createMonthsRangeForDate(date: Date, months: number): [Date, Date] {
	const start = addMonthsToDate(date, -months);
	const end = addMonthsToDate(date, months);
	return [start, end];
}

/**
 * Get most recent date.
 * @param dates Dates array.
 */
export function getMostRecentDate(dates: Array<Date | null>): Date | null {
	const filteredDates = dates.filter(Boolean);
	if (filteredDates.length === 0) {
		return null;
	}
	const maxDate = Math.max(...dates.map(d => Number(d)));
	return new Date(maxDate);
}

/**
 * Is date invalid.
 * @param date Date to check.
 */
export function isInvalidDate(date?: Date | null): boolean {
	return isNaN(Number(date));
}
